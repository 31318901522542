import React from 'react';

import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import TwitterI from '../../static/logo/twitter-icon.png';
import YoutubeI from '../../static/logo/youtube-icon.png';
import ItchI from '../../static/logo/itch-icon.png';
import UnityI from '../../static/logo/unity-icon.png';

const StyledLink = styled.a`
  display: flex;
  font-weight: 700;
  align-items: center;
  //height: 0.8rem;
  width: 1.4rem;

`;

const MediaBoxContainer = styled.div`
  display: inline-flex; 
  flex:  3 1 200px;
  width: 100rem;
  height: 2rem;
  //justify-content: flex-start;
  flex-grow: 0;
  object-fit: contain;
  position: relative;
  background: transparent;
  transition: .5s;
  
  &:hover 
  {    
    border-radius: 5px;
    box-shadow: 0 0.5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;
  }
@media (max-width: 600px) {
  display: none;
  }
  // :{}

`;
const Nav = styled(AniLink)`
  display: flex;
  font-family: ${props => props.theme.fontFamily.body2};
  font-weight: 600;
  font-size: 0.8rem;
align-self:center;



@media (max-width: 600px) {

//padding: 0 2rem;
//justify-content:stretch;
//margin-left: 0px;
//justify-content: center;
margin: 0 auto;
align-self: center;
      }




    a {
    text-transform: uppercase;
  background: linear-gradient(to right, rgba(56,30,201,1) 0%, rgba(208,66,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #183D58;
  margin-left: 20px;
  @media (max-width: 600px) {
    //min-width: calc(99.9vw/3  );
    //max-width: calc(99.9vw/3 );
padding: 0 1rem;
  //justify-content:stretch;
  margin-left: 0px;
  //margin: 0 auto;
   align-self:center;
  justify-content: safe-center;
  //align-content: space-between;
  font-weight: 600;
    text-transform: uppercase;
  background: linear-gradient(to right, rgba(56,30,201,1) 0%, rgba(208,66,255,1) 45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
        }


    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      //animation: text-shadow 1.5s ease-in-out infinite;
    font-size: 1em;
    font-weight: 999;

    }

  }

  
  
  
`;

const MediaLinksWarper = styled.div`
display:flex;
flex-direction:row;
position: absolute;
width: 200px;
margin-top:2%;
justify-content: space-around;;

  @media (max-width: 600px) {
    display: none;
    }
`;

const MediaBoxImg = styled.div`

@media (max-width: 600px) {
  display: none;
  }

`;
const YI = styled.div`
padding-top:2%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const UI = styled.div`
padding-top:1%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const II = styled.div`
padding-top:1%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const TI = styled.div`
padding-top:2%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;

const NavBar = () => (
  <Headroom calcHeightOnResize disableInlineStyles>

    <MediaBoxContainer>    

    <MediaLinksWarper> 
<YI>
<StyledLink href="https://www.youtube.com/channel/UCupf-2kducZfczqJgQbb1Dg/featured">
      <img style = {{ width:"100%",height:"100%"}} src={YoutubeI} alt="Limitless Logo" />
      </StyledLink>
</YI>
<TI>
<StyledLink href="/">
      <img style = {{ width:"100%",height:"100%"}} src={TwitterI} alt="Limitless Logo" />
      </StyledLink>
</TI>
<II>
<StyledLink href="https://limitless-development.itch.io">
      <img style = {{ width:"100%",height:"100%"}} src={ItchI} alt="Limitless Logo" />
      </StyledLink>
</II>
<UI>
<StyledLink href="https://assetstore.unity.com/publishers/39817">
      <img style = {{ width:"100%",height:"100%"}} src={UnityI} alt="Limitless Logo" />
      </StyledLink>
</UI>
      </MediaLinksWarper>
    </MediaBoxContainer>

    <Nav id = "navvB">
      <AniLink fade to="/" duration={0.2}>Home</AniLink>
      {/* <Link to="/blog">Blog</Link> */}
      <AniLink fade to="/documentation" duration={0.2}>Docs</AniLink>
      <AniLink fade top="exit" to="/about" duration={0.2}>Info</AniLink>
    </Nav>
  </Headroom>
);

export default NavBar;
