import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Tags from 'pages';

//
// &:hover:before, &:hover:after {
//   //display: flex;
//   content: '';
//   position: absolute;
//   // width: 100%;
//   // height: 100%;
//   background: #FDA8CF;
//   border-radius: 75px;
//   z-index: 1;
//   animation: 1s clockwise infinite;
// }

// &:hover:after {
//   background: #F3CE5E;
//   animation: 2s counterclockwise infinite;
// }

// @keyframes clockwise {
//   0% {
//     top: -5px;
//     left: 0;
//   }
//   12% {
//     top: -2px;
//     left: 2px;
//   }
//   25% {
//     top: 0;
//     left: 5px;    
//   }
//   37% {
//     top: 2px;
//     left: 2px;
//   }
//   50% {
//     top: 5px;
//     left: 0;    
//   }
//   62% {
//     top: 2px;
//     left: -2px;
//   }
//   75% {
//     top: 0;
//     left: -5px;
//   }
//   87% {
//     top: -2px;
//     left: -2px;
//   }
//   100% {
//     top: -5px;
//     left: 0;    
//   }
// }

// @keyframes counterclockwise {
//   0% {
//     top: -5px;
//     right: 0;
//   }
//   12% {
//     top: -2px;
//     right: 2px;
//   }
//   25% {
//     top: 0;
//     right: 5px;    
//   }
//   37% {
//     top: 2px;
//     right: 2px;
//   }
//   50% {
//     top: 5px;
//     right: 0;    
//   }
//   62% {
//     top: 2px;
//     right: -2px;
//   }
//   75% {
//     top: 0;
//     right: -5px;
//   }
//   87% {
//     top: -2px;
//     right: -2px;
//   }
//   100% {
//     top: -5px;
//     right: 0;    
//   }
// }
//
import { Container } from 'layouts';


const Wrapper = styled.article`
  //margin: 0 10rem;
display: flex;
//justify-content: stretch;
//flex-direction: row;
@media (max-width: 800px) {
  flex-direction: column;
  padding-bottom: 10%;
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;
  //border-radius: 1rem;
  z-index: 1;
}
background: #FFF;
//box-shadow: 0 4px 120px rgba(0,0,0,0.1);
transition: 0.6s;
//position:relative;
//z-index:10;
&:hover {
  
  //box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
  //box-shadow: 0 4px 120px rgba(0,0,0,0.1);
  //box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 2px 2px 0 #e0d9d9;

  transform: scale(1.02);
  //background: #f2edec;
  border-radius: 1rem;
}
`;

const Image = styled.div`
  //margin: 2rem 2rem;
  //display:flex;
  //position: relative;
  //align-self: center;
  //box-shadow: ${props => props.theme.shadow.feature.small.default};
  //transition: ${props => props.theme.transitions.boom.transition};
  //border-radius: ${props => props.theme.borderRadius.default};
  //min-height: 50%;
  img {
    border-radius: ${props => props.theme.borderRadius.default};
    object-fit: contain;
    position: relative;
    margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: 100%;
          top: 2.5rem;
  }

  //flex-basis: 20rem;
  
  //max-width: 100%;
  //width: 10rem;
  //height: 100%;
  //object-fit: contain;

  // @media (max-width: 800px) {
  //   flex-basis: 100%;
  //   max-width: 100%;
  //   width: 100%;
  //   margin-bottom: 1.5rem;
  // }
  // @media (max-width: 500px) {
  //   min-height: 200px;
  // }
`;

const Information = styled.div`
display: flex;  
//justify-self:end;
//flex-direction: column;
padding-top: 5%;

  text-align: left;
  // flex-basis: 100%;
  // max-width: 100%;
  // width: 100%;
  @media (max-width: 800px) {
    padding-top: 0%;
    border-bottom: 0.01px solid #f2edec;
    padding-bottom: 10%;
  }
`;

const Date = styled.div`
display: flex;  
margin-top: 1rem;
  color: ${props => props.theme.colors.black.lighter};
  justify-content:center;
`;
const TitleLink = styled.div`
display: flex;
align-self:center;
padding-top: 2%;
@media (max-width: 800px) {
  padding-top: 0%;
  padding-bottom: 0%;
}
`;
const Title = styled.h1`
  //margin: 0;
`;
const Category = styled.div`
display: flex;

justify-content: space-between;
padding: 0% 0 2% 0;
//background: ${props => props.theme.colors.black.blue};
font-size: 2rem;
`;
const LeftBlock = styled.div`
display: flex;  
justify-content:center;
flex-direction: column;
padding: 2%;
min-width: 50%;
margin-bottom: 1rem;
  h1 {
    font-size: 2rem;
    display: inline-block;
    color: ${props => props.theme.colors.black.lighter};
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      //color: ${props => props.theme.colors.primary.base};
    }
  }
  text-align: center;
`;
const RightBlock = styled.div`
display: flex;  
padding: 2%;
//justify-content:center;
flex-direction: column;
min-width: 50%;
//margin-bottom: 1rem;
//text-align: center;
`;
const BlogList = ({ path, cover, title, date, excerpt, tags }) => (
  <Container>
    <Wrapper>
      <LeftBlock>
      <Image>
        <Link to={path} title={title}>
          <Img fluid={cover} />
        </Link>
      </Image>
      <Date>{date}</Date>
      </LeftBlock>
   <RightBlock>
           <TitleLink>
        <Link style = {{color:"#2b2523"}} to={path}>
          <Title>{title}</Title>
        </Link>
        </TitleLink>
        <Information>

        {excerpt}     

         </Information>
      </RightBlock>  
    </Wrapper>
  </Container>
);

export default BlogList;

BlogList.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};
