import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import logo from '../../static/logo/logo-icon.png';
import { Link } from 'gatsby';

const Wrapper = styled.header`
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
  }
  background: ${props => props.theme.gradient.rightToLeft};
  //height: 200px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 200px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 275px;
  }
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
   padding-bottom:4rem;
   padding-top:4rem;
`;
const LogoImg = styled.div`

// position: absolute;
 margin-top:4%;
// left:-90px;
height: 4rem;
width: 3rem;
display:flex;
`;
const Text = styled.div`
  color: ${props => props.theme.colors.black.base};

  display: flex;
  flex-direction: column;
  //text-align: center;
  //width: 100%;
  max-width: ${props => props.theme.layout.base};
   padding: 0 1rem;
  // margin-bottom: 3rem;
  //align-items: center;
  flex:  0 1 0;
  //flex-grow: 0;
`;
const TextWarper = styled.div`
  color: ${props => props.theme.colors.black.base};
  flex:  3 1 100px;
  flex-grow: 0;

  display: inline-flex; 
  //flex:  3 1 200px;
  flex-direction: row;
  //text-align: center;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  //padding: 0 2rem;
  //margin-bottom: 3rem;
  //align-items: center;

`;

const Subtitle = styled.p`
  // max-width: 650px;

  font-size: 130%;
  // flex:  3 1 1px;
  // flex-grow: 0;

  display: flex;
  
  text-align: left;
  //align-items: center;
  line-height: 90%;
  color: #a5a5a5;
`;
const HSubtitle = styled.p`
  // max-width: 650px;
  h1{
    line-height: 0;
  }
  
  font-size: 130%;
  // flex:  0 1 0px;
  // flex-grow: 0;

  display: flex;
  
  text-align: left;

`;

const Header = ({ children, title, date, cover }) => (
  <Wrapper>
    {/* <Img fluid={cover || {} || [] || ''} /> */}
    <TextWarper>
    <LogoImg> 
      <Link to = "/">
      <img style = {{ width:"100%",height:"100%"}} src={logo} alt="Limitless Logo" />
      </Link>
</LogoImg>

    <Text>
    <Subtitle>{children}</Subtitle>
    <HSubtitle>

      <h1>{title}</h1>
      </HSubtitle>
      <h3>{date}</h3>
    </Text>
    </TextWarper>
  </Wrapper>
);

export default Header;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

Header.defaultProps = {
  children: false,
  cover: false,
  date: false,
  title: false,
};
