import React from 'react';
import styled from '@emotion/styled';
import TwitterI from '../../static/logo/twitter-icon.png';
import YoutubeI from '../../static/logo/youtube-icon.png';
import ItchI from '../../static/logo/itch-icon.png';
import UnityI from '../../static/logo/unity-icon.png';
const Wrapper = styled.footer`
  position: relative;
  display:flex;
  flex-direction:column;
  padding-bottom: 3%;
  box-shadow: 0 4px 120px rgba(0,0,0,0.1);
  //padding-top: 2rem;
  //bottom: 0;
  //box-shadow: ${props => props.theme.shadow.footer};
  background: ${props => props.theme.gradient.leftToRight};
  font-family: ${props => props.theme.fontFamily.body2};
  font-weight: 500;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    //padding-top: 7rem;
  }
`;
const StyledLink = styled.a`
  display: flex;
  font-weight: 700;
  align-items: center;
  //height: 0.8rem;
  width: 1.4rem;

`;
const Text = styled.div`
  //margin: 0;
  padding-top: 2rem;
  line-height: 0%;
  font-weight:300;
  letter-spacing:1px;
  word-spacing: 0.1px;
  font-family: ${props => props.theme.fontFamily.body};
  text-align: center;
  color: ${props => props.theme.colors.black.base};
  @media (max-width: 600px) {
    
    padding-top: 2rem;
    padding-bottom: 2rem;
    line-height: 0%;
    font-weight:200;
      }
`;
const YI = styled.div`
padding-top:2%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const UI = styled.div`
padding-top:1%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const II = styled.div`
padding-top:1%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const TI = styled.div`
padding-top:2%;
transition: 0.5s;
&:hover {

  transform: scale(1.03);
}
`;
const MediaLinksWarper = styled.div`
display:none;
flex-direction:row;
position: relative;
//margin-top: 3%;
width: 200px;
//margin-top:4%;
justify-content: space-between;
align-self: center;
  //align-items: center;
  //left: 60px;
  @media (max-width: 600px) {
    display: flex;
    //border-top: 0.01px solid black;
    }
`;
const MediaBoxContainer = styled.div`
//display: -webkit-inline-flex;
  display: flex; 
  flex-direction:column;
  flex:  3 1 200px;
  //width: 100rem;
  height: 2rem;
  justify-content: flex-start;
  //flex-shrink: 1;
  flex-grow: 0;
  object-fit: contain;
  position: relative;
//align-items: flex-start;
@media (max-width: 600px) {
  //display: none;
  }

`;

const Footer = () => (
  <Wrapper>
    {/* <MediaBoxContainer> */}
    <Text>
      <span>
        Limitless Unity Development . 2019        
      </span>
    </Text>
    <MediaLinksWarper> 
<YI>
<StyledLink href="https://www.youtube.com/channel/UCupf-2kducZfczqJgQbb1Dg/featured">
      <img style = {{ width:"100%",height:"100%"}} src={YoutubeI} alt="Limitless Logo" />
      </StyledLink>
</YI>
<TI>
<StyledLink href="/">
      <img style = {{ width:"100%",height:"100%"}} src={TwitterI} alt="Limitless Logo" />
      </StyledLink>
</TI>
<II>
<StyledLink href="https://limitless-development.itch.io">
      <img style = {{ width:"100%",height:"100%"}} src={ItchI} alt="Limitless Logo" />
      </StyledLink>
</II>
<UI>
<StyledLink href="https://assetstore.unity.com/publishers/39817">
      <img style = {{ width:"100%",height:"100%"}} src={UnityI} alt="Limitless Logo" />
      </StyledLink>
</UI>
      </MediaLinksWarper>

    {/* </MediaBoxContainer> */}
  </Wrapper>
);
export default Footer;
