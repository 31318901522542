import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { Header, PostList } from 'components';
import { Layout } from 'layouts';

const PostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  margin: -1rem 4rem 1rem 4rem;
  @media (max-width: 1000px) {
    margin: -1rem 2rem 1rem 2rem;
  }
  @media (max-width: 700px) {
    margin: -1rem 1rem 1rem 1rem;
  }
`;

// const LogoStyledLink = styled(Link)`
//   display: flex;
//   font-weight: 700;
//   //align-items: center;
//   //height: 2rem;
//   width: 1.4rem;

// `;
//UNITY DEVELOPMENT
const Index = ({ data }) => {
  const { edges } = data.posts;
  return (
    <Layout>
      <Helmet title={'Limitless Development'} />

      <Header title="LIMITLESS">
      {/* <LogoImg> 
      
      <img style = {{ width:"100%",height:"100%"}} src={logo} alt="Limitless Logo" />
      
</LogoImg> */}
      unity<br/> development</Header>
      <PostWrapper>
        {edges.map(({ node }) => (
          <PostList
            key={node.id}
            cover={node.frontmatter.cover.childImageSharp.fluid}
            path={node.frontmatter.path}
            title={node.frontmatter.title}
            inf2={node.frontmatter.inf2}
            benefits={node.frontmatter.benefits}
            inf3={node.frontmatter.inf3}
            excerpt={node.excerpt}
            spaces_count={node.frontmatter.spaces_count}
          />
        ))}
      </PostWrapper>
    </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            frontmatter: PropTypes.shape({
              cover: PropTypes.object.isRequired,
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              inf2: PropTypes.string,
              inf3: PropTypes.string,
              benefits: PropTypes.array,
              tags: PropTypes.array,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      limit: 6
      filter: { fileAbsolutePath: { glob: "**/posts/**/*.md" }  frontmatter: { published: { eq: false} } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          frontmatter {
            title
            path
            inf2
            inf3
            benefits
            spaces_count
            date(formatString: "MM.DD.YYYY")
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 650
                  quality: 70
                  traceSVG: { color: "#b4aded" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
