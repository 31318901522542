import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../config/theme';
import strasua from '../../static/font/strasua.ttf';

const Wrapper = styled.article`

  margin-bottom: 3rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  position: relative;
  //z-index: 100;
  border-radius: ${props => props.theme.borderRadius.default};
  //box-shadow: ${props => props.theme.shadow.feature.small.default};
  transition: ${props => props.theme.transitions.boom.transition};
  
  flex-basis: calc(99.9% / 3 - 0.5rem);
  flex-grow: 1;
  //max-width: calc(99.9% * 1 / 1 - 2.5rem);
  min-height: 30rem;
  
  //z-index: 101;
  width: calc(99.9% * 1 / 1 - 2.5rem);

  &:hover {
    //z-index: 102;
    //box-shadow: ${props => props.theme.shadow.feature.small.hover};       
    transform: scale(1.03);
  }

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    //max-width: calc(99.9% * 1 / 2 - 1rem);
    //max-width: calc(99.9% * 1 / 2 - 1rem);
    //height: 18rem;
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
    //max-width: 100%;
    //width: 100%;
    //height: 15rem;
  }
`;

const StyledLink = styled(Link)`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding: 1rem;
  // z-index: 3;
  border-radius: ${props => props.theme.borderRadius.default};
  
  // &:after {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: linear-gradient(
  //     to bottom,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 0.3) 50%,
  //     rgba(0, 0, 0, 0.7) 80%,
  //     rgba(0, 0, 0, 0.8) 100%
  //   );

  //   z-index: -10;
    border-radius: ${theme.borderRadius.default};
    transition: opacity ${theme.transitions.default.duration};
  }
`;

const Image = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  right: 0;
  left: 0;
  bottom: 0;
  //z-index: 1;
  object-fit: cover;



  border-radius: ${props => props.theme.borderRadius.default};
  img {
    border-radius: ${props => props.theme.borderRadius.default};
  }
  > div {
    position: static !important;
  }
  > div > div {
    position: static !important;
  }
`;

const Title = styled.h2`
color: ${props => props.theme.colors.white.light};
  text-align: center;
  position: relative;
  padding-top: 1.6em;
  //margin-left: auto;
    //margin-right: auto;  
    font-family: ${props => props.theme.fontFamily.body};
    font-size: 1.8em;
    font-weight: 600;
`;

const Info = styled.div`

    // //width: 100%;
    // //height: 100%;
    // position: relative;
    // flex-grow: 1;

    // //padding-bottom: 2;
    // //left: 0;
    // display: flex;
    // align-items: center;
    // align-content: center; 
    // justify-content: center; 
    // //overflow: auto;   

    //z-index: 3;
    //border-radius: ${props => props.theme.borderRadius.default};
    
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: ${props => props.theme.borderRadius.default};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0.7) 80%,
        rgba(0, 0, 0, 1.8) 100%
      );
  
      //z-index: -10;



`;


const Benefits = styled.div`
  color: ${props => props.theme.colors.white.light};
  display:flex;
  position: relative;
font-size:1.2rem;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  font-weight: 300;
  padding-bottom:2rem;
  text-align: left;
`;

const Price = styled.div`
  color: #98ffcc;
  font-style: ${strasua};
  font-weight: 800;
  position: relative;
    text-align: center;

    font-size: 2em;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.white.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  position: relative;

  padding: 2rem;

  //top: 40%;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //margin: auto;

`;


const PostList = ({ cover, path, date,spaces_count, title, excerpt, inf2, benefits, inf3 }) => (

  <Wrapper>
    <Image>
      <Img fluid={cover} />
    </Image>
    <Info/>
    <StyledLink to={path}>

      <Title>
        {/* <span>{date}</span> */}
        <a style = {{color:spaces_count}}>{title}</a> <a style = {{color:"#933A16"}}>{inf3}</a>
      </Title>

      {/* <Price>
        {inf3}
      </Price> */}

      <Description>
      <Benefits>{inf2}</Benefits>
        
        
          {benefits &&
            benefits.map(tag => {
              const upperTag = tag;
              return (
                // 
                <li>{upperTag}</li>
                // 
              );
            })}
        
      
      </Description>

      {/* <span>{inf3}</span> */}

    </StyledLink>
  </Wrapper>
);

export default PostList;

PostList.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  inf2: PropTypes.string,
  inf3: PropTypes.string,
  benefits: PropTypes.array,
  spaces_count: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
